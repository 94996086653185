import React from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Link,
  Stack,
  Typography,
} from "@mui/joy";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import { nameMaxLength, passwordMaxLength } from "../constants";
import AuthenticationLayout from "../layouts/AuthenticationLayout";
import useSnackbar from "../hooks/useSnackbar";
import useApi from "../hooks/useApi";
import useSession from "../hooks/useSession";
import useTitle from "../hooks/useTitle";
import useLanguage from "../hooks/useLanguage";

export default function SignupPage() {
  const { t, tpath } = useLanguage();
  useTitle(t("signUp.title"));
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const api = useApi();
  const session = useSession();

  return (
    <AuthenticationLayout>
      <Stack gap={4} sx={{ mt: 2 }}>
        <Stack gap={1}>
          <Typography component="h1" level="h3">
            {t("signUp.title")}
          </Typography>
          <Typography level="body-sm">
            {t("signUp.alreadyMember")}{" "}
            <Link component={RouterLink} to={tpath("/signin")} level="title-sm">
              {t("signUp.signIn")}
            </Link>
          </Typography>
        </Stack>
        <FormControl required>
          <FormLabel>{t("name")}</FormLabel>
          <Input
            type="text"
            name="name"
            slotProps={{
              input: {
                maxLength: nameMaxLength,
                autoComplete: "name",
              },
            }}
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
        </FormControl>
        <FormControl required>
          <FormLabel>{t("email")}</FormLabel>
          <Input
            type="email"
            name="email"
            slotProps={{
              input: {
                autoComplete: "email",
              },
            }}
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
        </FormControl>
        <FormControl required>
          <FormLabel>{t("password")}</FormLabel>
          <Input
            type="password"
            name="password"
            slotProps={{
              input: {
                maxLength: passwordMaxLength,
                autoComplete: "new-password",
              },
            }}
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
        </FormControl>
        <Button
          type="submit"
          fullWidth
          loading={loading}
          onClick={() => {
            setLoading(true);
            api({
              path: "/signup",
              method: "post",
              body: {
                name,
                email,
                password,
              },
              onDone: () => setLoading(false),
              onSuccess: (response) => {
                snackbar.success(t("signUp.success"));
                session.setUserId(response.userId);
                navigate(tpath("/dashboard"));
              },
            });
          }}
        >
          {t("signUp.action")}
        </Button>
      </Stack>
    </AuthenticationLayout>
  );
}
