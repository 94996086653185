import { Box, Button, Card, CardContent, Grid, Typography } from "@mui/joy";
import SignedOutLayout from "../layouts/SignedOutLayout";
import useSession from "../hooks/useSession";
import { Link as RouterLink } from "react-router-dom";
import homepageBackground from "../images/homepage-background.jpg";
import useLanguage from "../hooks/useLanguage";

export default function HomePage() {
  const { t, tpath } = useLanguage();
  const session = useSession();
  return (
    <SignedOutLayout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          maxWidth: 2000,
          mx: "auto",
        }}
      >
        <Box
          sx={{
            alignSelf: "stretch",
            backgroundImage: `url("${homepageBackground}")`,
            backgroundSize: {
              xs: "auto 100%",
              md: "100% auto",
            },
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            minHeight: { xs: 600, lg: 800 },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              mt: 10,
              color: "white",
              fontSize: 60,
              textShadow: "black 2px 2px",
            }}
            textAlign="center"
            level="h1"
          >
            {t("home.title")}
          </Typography>
          <Button
            component={RouterLink}
            size="lg"
            sx={{
              alignSelf: "center",
              px: 6,
              py: 2,
              my: 6,
              borderRadius: 999,
            }}
            to={tpath(session.userId === null ? "/signup" : "/dashboard")}
          >
            {t("home.startTherapy")}
          </Button>
        </Box>
        <Box sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <Typography
            level="h4"
            sx={{
              mt: 10,
              textAlign: "center",
              alignSelf: "center",
            }}
          >
            {t("home.subtitle1")}
          </Typography>
          <Typography
            level="h4"
            sx={{
              mt: 2,
              textAlign: "center",
              alignSelf: "center",
            }}
          >
            {t("home.subtitle2")}
          </Typography>
          <Grid container spacing={2} sx={{ mt: 10 }}>
            <Grid xs={12} md={6} lg={4}>
              <Card variant="soft">
                <CardContent>
                  <Typography level="title-lg">
                    ✨ {t("home.expertEnhanced.title")}
                  </Typography>
                  <Typography level="body-lg">
                    {t("home.expertEnhanced.description")}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid xs={12} md={6} lg={4}>
              <Card variant="soft">
                <CardContent>
                  <Typography level="title-lg">
                    🌍 {t("home.therapyForEveryone.title")}
                  </Typography>
                  <Typography level="body-lg">
                    {t("home.therapyForEveryone.description")}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid xs={12} md={6} lg={4}>
              <Card variant="soft">
                <CardContent>
                  <Typography level="title-lg">
                    ⏰ {t("home.helpWhenYouNeedIt.title")}
                  </Typography>
                  <Typography level="body-lg">
                    {t("home.helpWhenYouNeedIt.description")}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid xs={12} md={6} lg={4}>
              <Card variant="soft">
                <CardContent>
                  <Typography level="title-lg">
                    🌪 {t("home.tackleAnyChallenge.title")}
                  </Typography>
                  <Typography level="body-lg">
                    {t("home.tackleAnyChallenge.description")}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid xs={12} md={6} lg={4}>
              <Card variant="soft">
                <CardContent>
                  <Typography level="title-lg">
                    👍 {t("home.alwaysGettingBetter.title")}
                  </Typography>
                  <Typography level="body-lg">
                    {t("home.alwaysGettingBetter.description")}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid xs={12} md={6} lg={4}>
              <Card variant="soft">
                <CardContent>
                  <Typography level="title-lg">
                    🕵️ {t("home.talkWithoutFear.title")}
                  </Typography>
                  <Typography level="body-lg">
                    {t("home.talkWithoutFear.description")}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </SignedOutLayout>
  );
}
