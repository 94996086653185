import { Done } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/joy";
import useProfile from "../hooks/useProfile";
import React from "react";
import EmailVerificationModal from "../modals/EmailVerificationModal";
import useLanguage from "../hooks/useLanguage";

export default function SettingsEmailRow() {
  const profile = useProfile();
  const [open, setOpen] = React.useState(false);
  const { t } = useLanguage();
  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={{ flexGrow: 1, overflow: "hidden" }}>
        <Typography level="title-md">{t("settings.email")}</Typography>
        <Typography level="body-sm" noWrap>
          {profile?.email}
        </Typography>
      </Box>
      <Box>
        {profile?.emailVerified === true && (
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Done color="success" />
            <Typography level="title-md" color="success" noWrap>
              {t("settings.verified")}
            </Typography>
          </Box>
        )}
        {profile?.emailVerified === false && (
          <>
            <Button color="warning" onClick={() => setOpen(true)}>
              {t("settings.verify")}
            </Button>
            <EmailVerificationModal
              email={profile.email}
              open={open}
              onClose={() => setOpen(false)}
            />
          </>
        )}
      </Box>
    </Box>
  );
}
