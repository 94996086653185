import { Box, Button, Sheet } from "@mui/joy";
import ColorSchemeToggle from "./ColorSchemeToggle";
import useSession from "../hooks/useSession";
import { Link as RouterLink } from "react-router-dom";
import logo from "../images/logo.svg";
import LanguageToggle from "./LanguageToggle";
import useLanguage from "../hooks/useLanguage";

export default function SignedOutHeader() {
  const session = useSession();
  const { t, tpath } = useLanguage();
  return (
    <Sheet
      variant="soft"
      sx={{
        p: 1,
        display: "flex",
        alignItems: "center",
        gap: 1,
        height: 50,
      }}
    >
      <Box component="img" src={logo} sx={{ height: 40, width: 40 }} />
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <LanguageToggle />
        <ColorSchemeToggle />
        {session.userId === null && (
          <Button component={RouterLink} variant="plain" to={tpath("/signin")}>
            {t("header.signIn")}
          </Button>
        )}
        {session.userId === null && (
          <Button component={RouterLink} to={tpath("/signup")}>
            {t("header.signUp")}
          </Button>
        )}
        {session.userId !== null && (
          <Button component={RouterLink} to={tpath("/dashboard")}>
            {t("header.goToDashboard")}
          </Button>
        )}
      </Box>
    </Sheet>
  );
}
