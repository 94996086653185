import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
} from "@mui/joy";
import useApi from "../hooks/useApi";
import React from "react";
import { useNavigate } from "react-router-dom";
import useSnackbar from "../hooks/useSnackbar";
import useSession from "../hooks/useSession";
import useLanguage from "../hooks/useLanguage";

interface Props {
  open: boolean;
  onClose: () => void;
}

export default function SignoutModal(props: Props) {
  const api = useApi();
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const session = useSession();
  const { t, tpath } = useLanguage();
  return (
    <Modal open={props.open} onClose={props.onClose}>
      <ModalDialog>
        <DialogTitle>{t("signOut.action")}</DialogTitle>
        <DialogContent>{t("signOut.areYouSure")}</DialogContent>
        <Box sx={{ display: "flex", justifyContent: "end", gap: 1 }}>
          <Button variant="plain" onClick={props.onClose}>
            {t("cancel")}
          </Button>
          <Button
            loading={loading}
            onClick={() => {
              setLoading(true);
              api({
                path: "/signout",
                method: "post",
                onDone: () => setLoading(false),
                onSuccess: () => {
                  props.onClose();
                  snackbar.success(t("signOut.success"));
                  navigate(tpath("/"));
                  session.unsetUserId();
                },
              });
            }}
          >
            {t("signOut.action")}
          </Button>
        </Box>
      </ModalDialog>
    </Modal>
  );
}
