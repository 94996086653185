import { Box } from "@mui/joy";
import SignedInLayout from "../layouts/SignedInLayout";
import { AgentsResponse } from "../interfaces/agents";
import LoadingPage from "./LoadingPage";
import ErrorPage from "./ErrorPage";
import useGetApi from "../hooks/useGetApi";
import AgentCard from "../components/AgentCard";
import useTitle from "../hooks/useTitle";
import EmailVerificationCard from "../components/EmailVerificationCard";
import useProfile from "../hooks/useProfile";
import useLanguage from "../hooks/useLanguage";

export default function DashboardPage() {
  const { t } = useLanguage();
  useTitle(t("dashboard.title"));
  const agents = useGetApi<AgentsResponse>("/agents");
  const profile = useProfile();
  if (agents.status === "loading") {
    return <LoadingPage />;
  }
  if (agents.status === "error") {
    return <ErrorPage error={agents.error} />;
  }
  return (
    <SignedInLayout>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        {profile?.emailVerified === false && <EmailVerificationCard />}
        {agents.response.agents.map((agent) => (
          <AgentCard key={agent.name} agent={agent} />
        ))}
      </Box>
    </SignedInLayout>
  );
}
