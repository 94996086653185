import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemDecorator,
  Typography,
} from "@mui/joy";
import SignedOutLayout from "../layouts/SignedOutLayout";
import useSession from "../hooks/useSession";
import { Link as RouterLink } from "react-router-dom";
import useTitle from "../hooks/useTitle";
import useProfile from "../hooks/useProfile";
import useLanguage from "../hooks/useLanguage";

export default function PricingPage() {
  const { t, tpath } = useLanguage();
  useTitle(t("pricing.title"));
  const session = useSession();
  const profile = useProfile();
  return (
    <SignedOutLayout>
      <Box sx={{ p: 2 }}>
        <Typography level="h1" textAlign="center" gutterBottom>
          {t("pricing.title")}
        </Typography>
        <Grid
          container
          spacing={2}
          sx={{ justifyContent: "center", alignItems: "stretch" }}
        >
          <Grid xs={12} sm={6} md={4} lg={3}>
            <Card variant="outlined" sx={{ height: "100%" }}>
              <CardContent>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                  <Typography level="h2" textAlign="center">
                    🆓 {t("pricing.free.name")}
                  </Typography>
                  <Typography level="h3" textAlign="center">
                    {t("pricing.free.price")}
                  </Typography>
                  <Typography level="body-md">
                    {t("pricing.free.description")}
                  </Typography>
                  <List sx={{ alignSelf: "center" }}>
                    <ListItem>
                      <ListItemDecorator>📅</ListItemDecorator>{" "}
                      {t("pricing.free.sessionFrequency")}
                    </ListItem>
                    <ListItem>
                      <ListItemDecorator>🕒</ListItemDecorator>{" "}
                      {t("pricing.free.sessionDuration")}
                    </ListItem>
                  </List>
                </Box>
              </CardContent>
              <CardActions orientation="vertical">
                {session.userId === null && (
                  <Button
                    component={RouterLink}
                    size="lg"
                    sx={{ alignSelf: "center" }}
                    to={tpath("/signup")}
                  >
                    Sign Up
                  </Button>
                )}
              </CardActions>
            </Card>
          </Grid>
          <Grid xs={12} sm={6} md={4} lg={3}>
            <Card variant="outlined" sx={{ height: "100%" }}>
              <CardContent>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                  <Typography level="h2" textAlign="center">
                    ✨ {t("pricing.premium.name")}
                  </Typography>
                  <Typography level="h3" textAlign="center">
                    {t("pricing.premium.price")}
                  </Typography>
                  <Typography level="body-md">
                    {t("pricing.premium.description")}
                  </Typography>
                  <List sx={{ alignSelf: "center" }}>
                    <ListItem>
                      <ListItemDecorator>♾️</ListItemDecorator>{" "}
                      {t("pricing.premium.sessionFrequency")}
                    </ListItem>
                    <ListItem>
                      <ListItemDecorator>🕒</ListItemDecorator>{" "}
                      {t("pricing.premium.sessionDuration")}
                    </ListItem>
                  </List>
                </Box>
              </CardContent>
              <CardActions orientation="vertical">
                {session.userId === null && (
                  <Button
                    component={RouterLink}
                    size="lg"
                    sx={{ alignSelf: "center" }}
                    to={tpath("/signup")}
                  >
                    Sign Up
                  </Button>
                )}
                {profile?.tier === "free" && (
                  <Button
                    component={RouterLink}
                    size="lg"
                    sx={{ alignSelf: "center" }}
                    to={tpath("/upgrade")}
                  >
                    Upgrade Now
                  </Button>
                )}
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </SignedOutLayout>
  );
}
