import React from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Link,
  Stack,
  Typography,
} from "@mui/joy";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import { passwordMaxLength } from "../constants";
import AuthenticationLayout from "../layouts/AuthenticationLayout";
import useSnackbar from "../hooks/useSnackbar";
import useApi from "../hooks/useApi";
import useSession from "../hooks/useSession";
import useTitle from "../hooks/useTitle";
import useLanguage from "../hooks/useLanguage";

export default function SigninPage() {
  const { t, tpath } = useLanguage();
  useTitle(t("signIn.title"));
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const api = useApi();
  const session = useSession();

  return (
    <AuthenticationLayout>
      <Stack gap={4} sx={{ mt: 2 }}>
        <Stack gap={1}>
          <Typography component="h1" level="h3">
            {t("signIn.title")}
          </Typography>
          <Typography level="body-sm">
            {t("signIn.areYouNew")}{" "}
            <Link component={RouterLink} to={tpath("/signup")} level="title-sm">
              {t("signIn.signUp")}
            </Link>
          </Typography>
        </Stack>
        <FormControl required>
          <FormLabel>{t("email")}</FormLabel>
          <Input
            type="email"
            name="email"
            slotProps={{
              input: {
                autoComplete: "email",
              },
            }}
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
        </FormControl>
        <FormControl required>
          <FormLabel>{t("password")}</FormLabel>
          <Input
            type="password"
            name="password"
            slotProps={{
              input: {
                maxLength: passwordMaxLength,
                autoComplete: "password",
              },
            }}
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
          <Typography level="body-sm" sx={{ mt: 1 }}>
            <Link
              component={RouterLink}
              to={tpath("/password-recovery/request")}
            >
              {t("signIn.forgotPassword")}
            </Link>
          </Typography>
        </FormControl>
        <Button
          type="submit"
          fullWidth
          loading={loading}
          onClick={() => {
            setLoading(true);
            api({
              path: "/signin",
              method: "post",
              body: {
                email,
                password,
              },
              onDone: () => setLoading(false),
              onSuccess: (response) => {
                snackbar.success(t("signIn.success"));
                session.setUserId(response.userId);
                navigate(tpath("/dashboard"));
              },
            });
          }}
        >
          {t("signIn.action")}
        </Button>
      </Stack>
    </AuthenticationLayout>
  );
}
