import { Edit } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/joy";
import React from "react";
import PasswordChangeModal from "../modals/PasswordChangeModal";
import useLanguage from "../hooks/useLanguage";

export default function SettingsPasswordRow() {
  const [open, setOpen] = React.useState(false);
  const { t } = useLanguage();
  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={{ flexGrow: 1 }}>
        <Typography level="title-md">{t("settings.password")}</Typography>
        <Typography level="body-sm">******</Typography>
      </Box>
      <Box>
        <IconButton variant="soft" onClick={() => setOpen(true)}>
          <Edit />
        </IconButton>
        <PasswordChangeModal open={open} onClose={() => setOpen(false)} />
      </Box>
    </Box>
  );
}
