import { Navigate, useLocation } from "react-router-dom";

function storedLng(): "en" | "es" | null {
  const lng = localStorage.getItem("language");
  return lng === "en" || lng === "es" ? lng : null;
}

function navigatorLng(): "en" | "es" | null {
  const lng = navigator.language;
  if (lng === "en" || lng.startsWith("en-")) {
    return "en";
  }
  if (lng === "es" || lng.startsWith("es-")) {
    return "es";
  }
  return null;
}

export default function LanguageRedirectPage() {
  const location = useLocation();
  const lng = storedLng() ?? navigatorLng() ?? "en";
  return <Navigate to={`/${lng}${location.pathname}`} />;
}
