import { Delete } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/joy";
import React from "react";
import AccountDeletionModal from "../modals/AccountDeletionModal";
import useLanguage from "../hooks/useLanguage";

export default function SettingsDeleteAccountRow() {
  const [open, setOpen] = React.useState(false);
  const { t } = useLanguage();
  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={{ flexGrow: 1 }}>
        <Typography level="title-md">{t("settings.deleteAccount")}</Typography>
        <Typography level="body-sm">
          {t("settings.deleteAccountDescription")}
        </Typography>
      </Box>
      <Box>
        <IconButton color="danger" variant="soft" onClick={() => setOpen(true)}>
          <Delete />
        </IconButton>
        <AccountDeletionModal open={open} onClose={() => setOpen(false)} />
      </Box>
    </Box>
  );
}
