import { Box, Button, CircularProgress } from "@mui/joy";
import ChatMessage from "./ChatMessage";
import useChatEvent from "../hooks/useChatEvent";
import React from "react";
import {
  ChatEventMessage,
  ChatEventTyping,
  ChatMessagesResponse,
  ChatResponse,
} from "../interfaces/chats";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import useApi from "../hooks/useApi";
import ChatWarning from "./ChatWarning";
import chatBackground from "../images/chat-background.png";

interface Props {
  chat: ChatResponse;
}

interface Message {
  messageId: string;
  author: "assistant" | "system" | "user";
  content: string;
}

const loadMoreScrollTopLimit = 400;

export default function ChatMessages(props: Props) {
  const api = useApi();

  // List of all rendered messages.
  const [pastMessages, setPastMessages] = React.useState<Message[]>([]);
  const [messages, setMessages] = React.useState<Message[]>([]);

  // Whether new messages have been received which are hidden below.
  const [newMessages, setNewMessages] = React.useState(false);

  // Whether the scroll is all the way to the top/bottom.
  const sticky = React.useRef(true);

  // Reference to the parent scrollable box.
  const scrollBox = React.useRef<HTMLDivElement>();

  // Whether the assistant is typing a message.
  const [assistantTyping, setAssistantTyping] = React.useState(false);

  // Scrolls view to the bottom when called.
  const scrollToBottom = React.useCallback(() => {
    const elem = scrollBox.current;
    if (elem !== undefined) {
      elem.scrollTo({ top: elem.scrollHeight });
      setNewMessages(false);
    }
  }, []);

  // Scrolls view to the bottom if sticky.
  const maybeScrollToBottom = React.useCallback(() => {
    if (sticky.current) {
      scrollToBottom();
    }
  }, [scrollToBottom]);

  // Maybe scroll to bottom when a new message is received.
  React.useEffect(maybeScrollToBottom, [
    pastMessages,
    messages,
    assistantTyping,
    maybeScrollToBottom,
  ]);

  // Maybe scroll to bottom when the window is resized.
  React.useEffect(() => {
    window.addEventListener("resize", maybeScrollToBottom);
    return () => window.removeEventListener("resize", maybeScrollToBottom);
  }, [maybeScrollToBottom]);

  // Subscribe new message handler.
  const handleNewMessage = React.useCallback((event: ChatEventMessage) => {
    // Append new message at the end.
    const message = {
      messageId: event.message.messageId,
      author: event.message.author,
      content: event.message.content,
    };
    setMessages((messages) => [...messages, message]);

    // If the scroll is not at the bottom, then hint new messages.
    if (!sticky.current) {
      setNewMessages(true);
    }
  }, []);
  useChatEvent("message", handleNewMessage);

  // Subscribe typing status handler.
  const handleTyping = React.useCallback((event: ChatEventTyping) => {
    if (event.author === "assistant") {
      setAssistantTyping(event.typing);
    }
  }, []);
  useChatEvent("typing", handleTyping);

  // Whether more messages are loading.
  const [loading, setLoading] = React.useState(false);

  // Whether all messages have been loaded.
  const [allLoaded, setAllLoaded] = React.useState(false);

  // Load more messages.
  const loadMore = React.useCallback(() => {
    if (loading || allLoaded) {
      return;
    }
    const beforeMessageId = pastMessages.at(0)?.messageId;
    setLoading(true);
    api({
      path: `/chats/${props.chat.chatId}/messages?limit=10${
        beforeMessageId === undefined
          ? ""
          : `&beforeMessageId=${beforeMessageId}`
      }`,
      method: "GET",
      onDone: () => setLoading(false),
      onSuccess: (response: ChatMessagesResponse) => {
        const olderMessages = response.messages.map((message) => ({
          messageId: message.messageId,
          author: message.author,
          content: message.content,
        }));
        olderMessages.reverse();
        setPastMessages([...olderMessages, ...pastMessages]);
        setAllLoaded(response.messages.length === response.messagesCount);
      },
    });
  }, [api, pastMessages, loading, allLoaded, props.chat.chatId]);

  // Trigger loading more messages when within scroll top limit.
  React.useEffect(() => {
    const elem = scrollBox.current;
    if (elem !== undefined) {
      if (elem.scrollTop < loadMoreScrollTopLimit) {
        loadMore();
      }
    }
  }, [loadMore]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "sticky",
          width: "100%",
          height: "100%",
          backgroundImage: `url("${chatBackground}")`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: {
            xs: "300px auto",
            sm: "400px auto",
          },
          opacity: 0.5,
          zIndex: -1000,
        }}
      />
      <Box
        sx={{
          overflowY: "auto",
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        }}
        ref={scrollBox}
        onScroll={() => {
          // Stop sticky behavior if the user scrolls away from bottom.
          const elem = scrollBox.current;
          if (elem !== undefined) {
            sticky.current =
              elem.scrollTop + elem.clientHeight >= elem.scrollHeight;
            if (sticky.current) {
              setNewMessages(false);
            }
            if (elem.scrollTop < loadMoreScrollTopLimit) {
              loadMore();
            }
          }
        }}
      >
        <Box
          sx={{
            mx: "auto",
            maxWidth: "800px",
            p: 1,
            pt: 5,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          {loading && <CircularProgress sx={{ alignSelf: "center" }} />}
          {pastMessages.map((message) => (
            <ChatMessage
              key={message.messageId}
              author={message.author}
              content={message.content}
            />
          ))}
          {messages.map((message) => (
            <ChatMessage
              key={message.messageId}
              author={message.author}
              content={message.content}
            />
          ))}
          {assistantTyping && <ChatMessage author="assistant" content={null} />}
        </Box>
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          pt: 1,
        }}
      >
        <ChatWarning chat={props.chat} />
      </Box>
      {newMessages && (
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            color="warning"
            size="lg"
            sx={{
              mb: 2,
            }}
            startDecorator={<ArrowDownwardIcon />}
            endDecorator={<ArrowDownwardIcon />}
            onClick={() => {
              scrollToBottom();
            }}
          >
            new messages
          </Button>
        </Box>
      )}
    </Box>
  );
}
