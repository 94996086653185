const translation = {
  blogIndexPage: {
    next: "Next",
    page: "Page {{page}}",
    previous: "Previous",
    readMore: "Read more",
  },
  cancel: "Cancel",
  chat: {
    typeMessage: "Type a message",
  },
  dashboard: {
    continueSession: "Continue Session ({{timeLeft}} left)",
    startSessionNow: "Start {{durationMinutes}}-minute Session",
    startSessionWait: "Start Session {{timeToNext}}",
    title: "Dashboard",
    verifyEmailFirst: "You must verify your email first.",
  },
  deleteAccount: {
    action: "Delete my account",
    areYouSure: "Are you completely sure you want to delete your account?",
    description:
      "This operation will permanently delete your account and all the data associated with it.",
    enterPassword: "Enter your password to confirm.",
    success: "Account deleted.",
    title: "Delete Account",
    warning: "This operation can't be undone.",
  },
  email: "Email",
  emailVerificationCard: {
    action: "Verify my email",
    description:
      "Please verify your email address first. Once you do, you'll be able to start your first session with Christina.",
    title: "Verify your Email Address",
  },
  emailVerificationModal: {
    action: "Request Email",
    description:
      "Verify your account by opening the verification link we'll send to your email address: {{email}}",
    success: "Verification email sent to: {{email}}",
    title: "Verify Email",
  },
  emailVerificationPage: {
    failed: "Email Verification Failed",
    success: "Email verified.",
    title: "Email Verification",
    verified: "Email Verified",
    verifying: "Verifying your email...",
  },
  footer: {
    blog: "Blog",
    dashboard: "Dashboard",
    home: "Home",
    pricing: "Pricing",
    privacyPolicy: "Privacy Policy",
    settings: "Settings",
    termsOfService: "Terms of Service",
  },
  goHome: "Go to Home",
  header: {
    changeLanguage: "Change language",
    changeTheme: "Change theme",
    goToDashboard: "Go to Dashboard",
    signIn: "Sign In",
    signUp: "Sign Up",
  },
  home: {
    alwaysGettingBetter: {
      description:
        "Christina.app isn't static. Our AI therapist continuously learns and improves with your feedback, ensuring you receive the most effective support possible with each session.",
      title: "Always Getting Better",
    },
    expertEnhanced: {
      description:
        "Our AI therapist is constantly monitored and improved by real professionals. Summaries of sessions are reviewed by licensed therapists who provide feedback, making the AI smarter and more effective over time.",
      title: "Expert-Enhanced AI",
    },
    helpWhenYouNeedIt: {
      description:
        "Life doesn't wait for office hours. With Christina.app, you can have a therapeutic conversation whenever you need it, day or night, from the comfort of your own space.",
      title: "Help When You Need It",
    },
    startTherapy: "Start Therapy Now",
    subtitle1:
      "Enjoy confidential, professional guidance on your schedule, at a fraction of the cost of traditional therapy.",
    subtitle2:
      "Get help with anxiety, mood disorders, life issues, and more, without the stigma of seeing a human therapist.",
    tackleAnyChallenge: {
      description:
        "Whether it's your mood, anxiety, work stress, or relationship problems, Christina.app is equipped to help you navigate and overcome a wide range of emotional challenges.",
      title: "Tackle Any Challenge",
    },
    talkWithoutFear: {
      description:
        "No need to feel embarrassed or judged. Christina.app ensures complete confidentiality by using AI for therapy, so your secrets stay safe with the machine.",
      title: "Talk Without Fear",
    },
    therapyForEveryone: {
      description:
        "Christina.app makes therapy accessible to all by significantly reducing costs. No need to pay for expensive 1:1 sessions—get the help you need at a fraction of the cost.",
      title: "Therapy for Everyone",
    },
    title: "AI-Powered Therapy at Your Fingertips",
  },
  localeChange: {
    success: "Language updated.",
  },
  name: "Name",
  nameChange: {
    newName: "New Name",
    success: "Name updated.",
    title: "Edit Name",
  },
  password: "Password",
  passwordChange: {
    currentPassword: "Current password",
    newPassword: "New password",
    passwordMismatch: "Passwords do not match.",
    repeatPassword: "Repeat new password",
    success: "Password updated.",
    title: "Edit Password",
  },
  passwordRecoveryRequest: {
    action: "Recover password",
    checkInbox:
      "Please check your inbox. We've sent you an email with instructions to recover access to your account.",
    description: "We'll send you an email with instructions.",
    success: "Recovery email sent.",
    title: "Password Recovery",
  },
  passwordRecoveryReset: {
    action: "Reset password",
    description: "Set up a new password for your account.",
    newPassword: "New password",
    success: "New password saved.",
    title: "Password Reset",
  },
  pricing: {
    free: {
      description:
        "We believe everyone should have access to therapy, regardless of financial circumstances. Perfect for those starting their mental health journey.",
      name: "Free",
      price: "$0 forever",
      sessionDuration: "10 minutes / session",
      sessionFrequency: "1 session / day",
    },
    premium: {
      description:
        "For those seeking deeper, more comprehensive support. Ideal for individuals committed to long-term mental wellness.",
      name: "Premium",
      price: "$20 / month",
      sessionDuration: "30 minutes / session",
      sessionFrequency: "Unlimited sessions",
    },
    title: "Pricing",
  },
  privacyPolicy: {
    title: "Privacy Policy",
  },
  save: "Save",
  settings: {
    adminPanel: "Admin Panel",
    deleteAccount: "Delete Account",
    deleteAccountDescription: "Permanently delete your account.",
    email: "Email",
    language: "Language",
    languageForEmails: "Language you want to receive emails in.",
    manageSubscription: "Manage Subscription",
    name: "Name",
    password: "Password",
    signOut: "Sign Out",
    tier: {
      free: {
        description: "Limited to one 10-minute session every day.",
        title: "Free Account",
      },
      premium: {
        description: "Unlimited access to 30-minute sessions.",
        title: "Premium account",
      },
    },
    title: "Settings",
    verified: "Verified",
    verify: "Verify",
  },
  signIn: {
    action: "Sign in",
    areYouNew: "New to Christina.app?",
    forgotPassword: "Forgot your password?",
    signUp: "Sign up!",
    success: "You are signed in.",
    title: "Sign In",
  },
  signOut: {
    action: "Sign Out",
    areYouSure: "Are you sure you want to sign out?",
    success: "You are signed out.",
  },
  signUp: {
    action: "Sign up",
    alreadyMember: "Already a member?",
    signIn: "Sign in!",
    success: "New account created.",
    title: "Sign Up",
  },
  termsOfService: {
    title: "Terms of Service",
  },
  upgradePage: {
    title: "Upgrade",
  },
};

export default translation;
