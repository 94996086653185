import { Box } from "@mui/joy";
import { Dashboard, Settings } from "@mui/icons-material";
import FooterMenuItem from "./FooterMenuItem";
import useLanguage from "../hooks/useLanguage";

export default function SignedInFooter() {
  const { t } = useLanguage();
  return (
    <Box
      sx={{
        bgcolor: "background.surface",
        p: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
      }}
    >
      <FooterMenuItem
        icon={<Dashboard />}
        name={t("footer.dashboard")}
        path="/dashboard"
      />
      <FooterMenuItem
        icon={<Settings />}
        name={t("footer.settings")}
        path="/settings"
      />
    </Box>
  );
}
