import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalDialog,
  Typography,
} from "@mui/joy";
import useApi from "../hooks/useApi";
import React from "react";
import useSnackbar from "../hooks/useSnackbar";
import { passwordMaxLength } from "../constants";
import { useNavigate } from "react-router-dom";
import useSession from "../hooks/useSession";
import useLanguage from "../hooks/useLanguage";

interface Props {
  open: boolean;
  onClose: () => void;
}

export default function AccountDeletionModal(props: Props) {
  const [accepted, setAccepted] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const api = useApi();
  const [loading, setLoading] = React.useState(false);
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const session = useSession();
  const { t, tpath } = useLanguage();
  return (
    <Modal open={props.open} onClose={props.onClose}>
      <ModalDialog sx={{ gap: 2 }}>
        <DialogTitle>{t("deleteAccount.title")}</DialogTitle>
        <DialogContent sx={{ gap: 2 }}>
          <Typography>{t("deleteAccount.description")}</Typography>
          <Typography fontWeight="bold" color="danger">
            {t("deleteAccount.warning")}
          </Typography>
          {!accepted && (
            <Typography>{t("deleteAccount.areYouSure")}</Typography>
          )}
          {accepted && (
            <FormControl required>
              <FormLabel>{t("password")}</FormLabel>
              <Input
                type="password"
                name="password"
                slotProps={{
                  input: {
                    maxLength: passwordMaxLength,
                    autoComplete: "password",
                  },
                }}
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
              <FormHelperText>
                {t("deleteAccount.enterPassword")}
              </FormHelperText>
            </FormControl>
          )}
        </DialogContent>
        <Box sx={{ display: "flex", justifyContent: "end", gap: 1 }}>
          <Button variant="plain" onClick={props.onClose}>
            {t("cancel")}
          </Button>
          <Button
            loading={loading}
            onClick={
              accepted
                ? () => {
                    setLoading(true);
                    api({
                      path: "/delete-account",
                      method: "post",
                      body: { password },
                      onDone: () => setLoading(false),
                      onSuccess: () => {
                        props.onClose();
                        session.unsetUserId();
                        navigate(tpath("/"));
                        snackbar.success(t("deleteAccount.success"));
                      },
                    });
                  }
                : () => setAccepted(true)
            }
            color="danger"
          >
            {t("deleteAccount.action")}
          </Button>
        </Box>
      </ModalDialog>
    </Modal>
  );
}
