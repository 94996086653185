import React from "react";
import StripePricingTable from "../components/StripePricingTable";
import useApi from "../hooks/useApi";
import SignedInLayout from "../layouts/SignedInLayout";
import { StripeCustomerSessionResponse } from "../interfaces/stripe";
import LoadingPage from "./LoadingPage";
import ErrorPage from "./ErrorPage";
import useTitle from "../hooks/useTitle";
import useLanguage from "../hooks/useLanguage";

interface StateLoading {
  status: "loading";
}

interface StateSuccess {
  status: "success";
  clientSecret: string;
}

interface StateError {
  status: "error";
  error: string;
}

type State = StateLoading | StateSuccess | StateError;

const refreshMs = 10 * 60 * 1000; // 10 minutes

export default function UpgradePage() {
  const { t } = useLanguage();
  useTitle(t("upgradePage.title"));
  const api = useApi();
  const [state, setState] = React.useState<State>({ status: "loading" });
  React.useEffect(() => {
    let timeoutId: undefined | NodeJS.Timeout = undefined;
    const refresh = () => {
      api({
        method: "POST",
        path: "/stripe/customer-session",
        onSuccess: (response: StripeCustomerSessionResponse) => {
          setState({ status: "success", clientSecret: response.clientSecret });
          timeoutId = setTimeout(refresh, refreshMs);
        },
        onError: (error) => setState({ status: "error", error }),
        retry: true,
      });
    };
    refresh();
    return () => clearTimeout(timeoutId);
  }, [api]);

  if (state.status === "loading") {
    return <LoadingPage />;
  }

  if (state.status === "error") {
    return <ErrorPage error={state.error} />;
  }

  return (
    <SignedInLayout>
      <StripePricingTable clientSecret={state.clientSecret} />
    </SignedInLayout>
  );
}
