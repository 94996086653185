import { Logout } from "@mui/icons-material";
import { Button } from "@mui/joy";
import React from "react";
import SignoutModal from "../modals/SignoutModal";
import useLanguage from "../hooks/useLanguage";

export default function SettingsSignoutRow() {
  const [open, setOpen] = React.useState(false);
  const { t } = useLanguage();
  return (
    <>
      <Button
        sx={{ alignSelf: "center" }}
        color="neutral"
        startDecorator={<Logout />}
        onClick={() => setOpen(true)}
      >
        {t("settings.signOut")}
      </Button>
      <SignoutModal open={open} onClose={() => setOpen(false)} />
    </>
  );
}
