const translation = {
  blogIndexPage: {
    next: "Siguiente",
    page: "Página {{page}}",
    previous: "Anterior",
    readMore: "Leer más",
  },
  cancel: "Cancelar",
  chat: {
    typeMessage: "Escribe un mensaje",
  },
  dashboard: {
    continueSession: "Continuar sesión (quedan {{timeLeft}})",
    startSessionNow: "Empezar sesión de {{durationMinutes}} minutos",
    startSessionWait: "Empieza sesión {{timeToNext}}",
    title: "Panel",
    verifyEmailFirst: "Primero debes verificar tu correo electrónico.",
  },
  deleteAccount: {
    action: "Eliminar mi cuenta",
    areYouSure:
      "¿Estás completamente seguro/a de que deseas eliminar tu cuenta?",
    description:
      "Esta operación eliminará tu cuenta de forma permanente, junto con todos los datos asociados con la misma.",
    enterPassword: "Introduce tu contraseña para confirmar.",
    success: "Cuenta eliminada.",
    title: "Eliminar cuenta",
    warning: "Esta operación es irreversible.",
  },
  email: "Correo electrónico",
  emailVerificationCard: {
    action: "Verificar correo electrónico",
    description:
      "Por favor, verifica primero tu dirección de correo electónico. Una vez verificado podrás empezar tu primera sesión de terapia con Christina.",
    title: "Verificación de correo electrónico",
  },
  emailVerificationModal: {
    action: "Solicitar verificación",
    description:
      "Verifica tu cuenta abriendo el link de verificación que te enviaremos a tu dirección correo electrónico: {{email}}",
    success: "Correo de verificación enviado a: {{email}}",
    title: "Verificar correo electrónico",
  },
  emailVerificationPage: {
    failed: "Verificación de correo electrónico fallida",
    success: "Correo electrónico verificado.",
    title: "Verificación de correo electrónico",
    verified: "Correo electrónico verificado",
    verifying: "Verificando correo electrónico...",
  },
  footer: {
    blog: "Blog",
    dashboard: "Panel",
    home: "Inicio",
    pricing: "Planes y precios",
    privacyPolicy: "Política de privacidad",
    settings: "Ajustes",
    termsOfService: "Términos y condiciones",
  },
  goHome: "Ir a inicio",
  header: {
    changeLanguage: "Cambiar idioma",
    changeTheme: "Cambiar color",
    goToDashboard: "Ir al panel",
    signIn: "Iniciar sesión",
    signUp: "Crear cuenta",
  },
  home: {
    alwaysGettingBetter: {
      description:
        "Christina.app no es estática. Nuestra terapeuta de IA aprende y mejora continuamente con tu retroalimentación, asegurando que recibas el apoyo más efectivo posible en cada sesión.",
      title: "Siempre mejorando",
    },
    expertEnhanced: {
      description:
        "Nuestra terapeuta de IA es supervisada y mejorada constantemente por profesionales reales. Los resúmenes de las sesiones son revisados por terapeutas licenciados que proporcionan retroalimentación, haciendo que la IA sea más inteligente y efectiva con el tiempo.",
      title: "IA mejorada por expertos",
    },
    helpWhenYouNeedIt: {
      description:
        "La vida no espera a los horarios de oficina. Con Christina.app, puedes tener una conversación terapéutica cuando la necesites, de día o de noche, desde la comodidad de tu propio espacio.",
      title: "Ayuda cuando la necesites",
    },
    startTherapy: "Empieza la terapia ahora",
    subtitle1:
      "Disfruta de orientación confidencial y profesional en tu propio horario, a una fracción del coste de la terapia tradicional.",
    subtitle2:
      "Obtén ayuda para la ansiedad, trastornos del estado de ánimo, problemas de la vida y más, sin el estigma de acudir a un terapeuta humano.",
    tackleAnyChallenge: {
      description:
        "Ya sea tu estado de ánimo, ansiedad, estrés laboral o problemas de relaciones, Christina.app está equipada para ayudarte a navegar y superar una amplia gama de desafíos emocionales.",
      title: "Enfréntate a cualquier desafío",
    },
    talkWithoutFear: {
      description:
        "No tienes que sentirte avergonzado o juzgado. Christina.app garantiza una confidencialidad total mediante el uso de IA para la terapia, por lo que tus secretos estarán seguros con la máquina.",
      title: "Habla sin miedo",
    },
    therapyForEveryone: {
      description:
        "Christina.app hace que la terapia sea accesible para todos al reducir significativamente los costes. No es necesario pagar por costosas sesiones 1:1: obtén la ayuda que necesitas a una fracción del precio.",
      title: "Terapia para todos",
    },
    title: "Terapia impulsada por IA a tu alcance",
  },
  localeChange: {
    success: "Idioma actualizado.",
  },
  name: "Nombre",
  nameChange: {
    newName: "Nuevo nombre",
    success: "Nombre actualizado.",
    title: "Cambiar nombre",
  },
  password: "Contraseña",
  passwordChange: {
    currentPassword: "Contraseña actual",
    newPassword: "Nueva contraseña",
    passwordMismatch: "Las contraseñas no coinciden.",
    repeatPassword: "Repetir nueva contraseña",
    success: "Contraseña actualizada.",
    title: "Cambiar contraseña",
  },
  passwordRecoveryRequest: {
    action: "Recuperar contraseña",
    checkInbox:
      "Comprueba tu bandeja de entrada. Te hemos enviado un correo electrónico con instrucciones para recuperar el acceso a tu cuenta.",
    description: "Te enviaremos un correo electrónico con instrucciones.",
    success: "Correo electrónico de recuperación enviado.",
    title: "Recuperar contraseña",
  },
  passwordRecoveryReset: {
    action: "Establecer contraseña",
    description: "Establece una nueva contraseña para tu cuenta.",
    newPassword: "Nueva contraseña",
    success: "Contraseña actualizada.",
    title: "Restablecer contraseña",
  },
  pricing: {
    free: {
      description:
        "Creemos que todo el mundo debería tener acceso a la terapia, independientemente de su situación económica. Perfecto para aquellos que están comenzando su camino hacia la salud mental.",
      name: "Gratis",
      price: "$0 para siempre",
      sessionDuration: "10 minuts / sesión",
      sessionFrequency: "1 sesión / día",
    },
    premium: {
      description:
        "Para aquellos que buscan un apoyo más profundo y completo. Ideal para personas comprometidas con su bienestar mental a largo plazo.",
      name: "Premium",
      price: "$20 / mes",
      sessionDuration: "30 minutos / sesión",
      sessionFrequency: "Sesiones ilimitadas",
    },
    title: "Planes y precios",
  },
  privacyPolicy: {
    title: "Política de privacidad",
  },
  save: "Guardar",
  settings: {
    adminPanel: "Panel de administración",
    deleteAccount: "Eliminar cuenta",
    deleteAccountDescription: "Eliminar mi cuenta de forma permanente.",
    email: "Correo electrónico",
    language: "Idioma",
    languageForEmails: "Idioma en el que quieres recibir correos electrónicos.",
    manageSubscription: "Administrar suscripción",
    name: "Nombre",
    password: "Contraseña",
    signOut: "Cerrar sesión",
    tier: {
      free: {
        description: "Límite de una sesión de 10 minutos al día.",
        title: "Cuenta gratis",
      },
      premium: {
        description: "Acceso sin límite a sesiones de 30 minutos.",
        title: "Cuenta premium",
      },
    },
    title: "Ajustes",
    verified: "Verificado",
    verify: "Verificar",
  },
  signIn: {
    action: "Iniciar sesión",
    areYouNew: "¿Tu primera vez en Christina.app?",
    forgotPassword: "¿Olvidaste tu contraseña?",
    signUp: "¡Crea una cuenta!",
    success: "Has iniciado sesión.",
    title: "Iniciar sesión",
  },
  signOut: {
    action: "Cerrar sesión",
    areYouSure: "¿Seguro que quieres cerrar sesión?",
    success: "Has cerrado sesión.",
  },
  signUp: {
    action: "Crear cuenta",
    alreadyMember: "¿Ya estás registrado?",
    signIn: "¡Inicia sesión!",
    success: "Has creado una cuenta nueva.",
    title: "Crea una cuenta",
  },
  termsOfService: {
    title: "Términos y condiciones",
  },
  upgradePage: {
    title: "Hazte premium",
  },
};

export default translation;
