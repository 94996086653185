import { Box } from "@mui/joy";
import useSession from "../hooks/useSession";
import { useNavigate } from "react-router-dom";
import React from "react";
import SignedInHeader from "../components/SignedInHeader";
import SignedInFooter from "../components/SignedInFooter";
import useLanguage from "../hooks/useLanguage";

interface Props {
  children: React.ReactNode;
}

export default function SignedInLayout(props: Props) {
  const session = useSession();
  const navigate = useNavigate();
  const { tpath } = useLanguage();
  React.useEffect(() => {
    if (session.userId === null) {
      navigate(tpath("/"));
    }
  }, [session.userId, navigate, tpath]);

  return (
    <Box
      sx={{
        bgcolor: "background.appBody",
        height: "100dvh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <SignedInHeader />
      <Box component="main" sx={{ p: 2, flexGrow: 1, overflowY: "auto" }}>
        {props.children}
      </Box>
      <SignedInFooter />
    </Box>
  );
}
