import "@fontsource/inter";
import { CssBaseline, CssVarsProvider } from "@mui/joy";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import theme from "./theme";
import SnackbarProvider from "./providers/SnackbarProvider";
import SessionProvider from "./providers/SessionProvider";
import EventsProvider from "./providers/UserEventsProvider";
import ProfileProvider from "./providers/ProfileProvider";
import React, { Suspense } from "react";
import LoadingPage from "./pages/LoadingPage";
import LanguageProvider from "./providers/LanguageProvider";
import AppRoutes from "./AppRoutes";
import LanguageRedirectPage from "./pages/LanguageRedirectPage";

const AdminApp = React.lazy(() => import("./admin/AdminApp"));

export default function App() {
  return (
    <Suspense fallback={<LoadingPage />}>
      <LanguageProvider lng="en">
        <CssVarsProvider theme={theme} defaultColorScheme="light">
          <CssBaseline />
          <SessionProvider>
            <EventsProvider>
              <ProfileProvider>
                <SnackbarProvider>
                  <BrowserRouter>
                    <Routes>
                      <Route path="/en/*" element={<AppRoutes />} />
                      <Route
                        path="/es/*"
                        element={
                          <LanguageProvider lng="es">
                            <AppRoutes />
                          </LanguageProvider>
                        }
                      />
                      <Route path="/admin/*" element={<AdminApp />} />
                      <Route path="*" element={<LanguageRedirectPage />} />
                    </Routes>
                  </BrowserRouter>
                </SnackbarProvider>
              </ProfileProvider>
            </EventsProvider>
          </SessionProvider>
        </CssVarsProvider>
      </LanguageProvider>
    </Suspense>
  );
}
