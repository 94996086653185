import { Dropdown, Menu, MenuButton, MenuItem, Tooltip } from "@mui/joy";
import useLanguage from "../hooks/useLanguage";
import { Link, useLocation } from "react-router-dom";

export default function LanguageToggle() {
  const { lng, t } = useLanguage();
  const location = useLocation();
  const path = location.pathname.split("/").slice(2).join("/");
  return (
    <Dropdown>
      <Tooltip title={t("header.changeLanguage")} variant="outlined">
        <MenuButton variant="plain">
          {lng === "es" ? "🇪🇸 ES" : "🇬🇧 EN"}
        </MenuButton>
      </Tooltip>
      <Menu>
        <MenuItem
          selected={lng === "en"}
          component={Link}
          to={`/en/${path}`}
          onClick={() => localStorage.setItem("language", "en")}
        >
          🇬🇧 EN
        </MenuItem>
        <MenuItem
          selected={lng === "es"}
          component={Link}
          to={`/es/${path}`}
          onClick={() => localStorage.setItem("language", "es")}
        >
          🇪🇸 ES
        </MenuItem>
      </Menu>
    </Dropdown>
  );
}
