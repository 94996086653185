import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  Typography,
} from "@mui/joy";
import useApi from "../hooks/useApi";
import React from "react";
import useSnackbar from "../hooks/useSnackbar";
import useLanguage from "../hooks/useLanguage";

interface Props {
  email: string;
  open: boolean;
  onClose: () => void;
}

export default function EmailVerificationModal(props: Props) {
  const api = useApi();
  const [loading, setLoading] = React.useState(false);
  const snackbar = useSnackbar();
  const { t } = useLanguage();
  return (
    <Modal open={props.open} onClose={props.onClose}>
      <ModalDialog sx={{ gap: 2 }}>
        <DialogTitle>{t("emailVerificationModal.title")}</DialogTitle>
        <DialogContent>
          <Typography>
            {t("emailVerificationModal.description", { email: props.email })}
          </Typography>
        </DialogContent>
        <Box sx={{ display: "flex", justifyContent: "end", gap: 1 }}>
          <Button variant="plain" onClick={props.onClose}>
            {t("cancel")}
          </Button>
          <Button
            loading={loading}
            onClick={() => {
              setLoading(true);
              api({
                path: "/email-verification/request",
                method: "post",
                onDone: () => setLoading(false),
                onSuccess: () => {
                  props.onClose();
                  snackbar.success(
                    t("emailVerificationModal.success", { email: props.email })
                  );
                },
              });
            }}
          >
            {t("emailVerificationModal.action")}
          </Button>
        </Box>
      </ModalDialog>
    </Modal>
  );
}
