import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import React from "react";
import ChatPage from "./pages/ChatPage";
import DashboardPage from "./pages/DashboardPage";
import EmailVerificationPage from "./pages/EmailVerificationPage";
import PasswordRecoveryRequestPage from "./pages/PasswordRecoveryRequest";
import PasswordRecoveryResetPage from "./pages/PasswordRecoveryReset";
import PricingPage from "./pages/PricingPage";
import SettingsPage from "./pages/SettingsPage";
import SigninPage from "./pages/SigninPage";
import SignupPage from "./pages/SignupPage";
import UpgradePage from "./pages/UpgradePage";

const BlogIndexPage = React.lazy(() => import("./pages/BlogIndexPage"));
const BlogPostPage = React.lazy(() => import("./pages/BlogPostPage"));
const PrivacyPolicyPage = React.lazy(() => import("./pages/PrivacyPolicyPage"));
const TermsOfServicePage = React.lazy(
  () => import("./pages/TermsOfServicePage")
);

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/blog" element={<BlogIndexPage />} />
      <Route path="/blog/page/:page" element={<BlogIndexPage />} />
      <Route path="/blog/:vanityUrl" element={<BlogPostPage />} />
      <Route path="/chats/:chatId" element={<ChatPage />} />
      <Route path="/dashboard" element={<DashboardPage />} />
      <Route
        path="/email-verification/verify/:token"
        element={<EmailVerificationPage />}
      />
      <Route
        path="/password-recovery/request"
        element={<PasswordRecoveryRequestPage />}
      />
      <Route
        path="/password-recovery/reset/:token"
        element={<PasswordRecoveryResetPage />}
      />
      <Route path="/pricing" element={<PricingPage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
      <Route path="/settings" element={<SettingsPage />} />
      <Route path="/signin" element={<SigninPage />} />
      <Route path="/signup" element={<SignupPage />} />
      <Route path="/settings" element={<SettingsPage />} />
      <Route path="/signin" element={<SigninPage />} />
      <Route path="/signup" element={<SignupPage />} />
      <Route path="/terms-of-service" element={<TermsOfServicePage />} />
      <Route path="/upgrade" element={<UpgradePage />} />
    </Routes>
  );
}
