import { Button, Card, CardActions, CardContent, Typography } from "@mui/joy";
import EmailVerificationModal from "../modals/EmailVerificationModal";
import React from "react";
import useProfile from "../hooks/useProfile";
import useLanguage from "../hooks/useLanguage";

export default function EmailVerificationCard() {
  const [open, setOpen] = React.useState(false);
  const profile = useProfile();
  const { t } = useLanguage();
  return (
    <>
      <Card orientation="vertical" variant="outlined">
        <CardContent>
          <Typography level="title-lg">
            {t("emailVerificationCard.title")} ✉️
          </Typography>
          <Typography level="body-sm">
            {t("emailVerificationCard.description")}
          </Typography>
        </CardContent>
        <CardActions orientation="vertical">
          <Button
            variant="solid"
            size="md"
            color="primary"
            sx={{ alignSelf: "center" }}
            onClick={() => setOpen(true)}
          >
            {t("emailVerificationCard.action")}
          </Button>
        </CardActions>
      </Card>
      <EmailVerificationModal
        email={profile?.email ?? ""}
        open={open}
        onClose={() => setOpen(false)}
      />
    </>
  );
}
