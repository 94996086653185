import { Box, Link, Sheet, Typography } from "@mui/joy";
import ColorSchemeToggle from "./ColorSchemeToggle";
import logo from "../images/logo.svg";
import { Link as RouterLink } from "react-router-dom";
import useProfile from "../hooks/useProfile";
import useLanguage from "../hooks/useLanguage";

export default function SignedInHeader() {
  const profile = useProfile();
  const { tpath } = useLanguage();
  return (
    <Sheet
      variant="soft"
      sx={{
        p: 1,
        display: "flex",
        alignItems: "center",
        gap: 1,
        height: 50,
      }}
    >
      <Link component={RouterLink} to={tpath("/")}>
        <Box component="img" src={logo} sx={{ height: 40, width: 40 }} />
      </Link>
      <Box
        sx={{
          flexGrow: 1,
        }}
      >
        <Typography level="title-lg">
          christina.app{profile?.tier === "premium" ? " ✨ Premium" : ""}
        </Typography>
      </Box>
      <ColorSchemeToggle />
    </Sheet>
  );
}
