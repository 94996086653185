import { Box, Link } from "@mui/joy";
import { Link as RouterLink } from "react-router-dom";
import Copyright from "./Copyright";
import useLanguage from "../hooks/useLanguage";

export default function SignedOutFooter() {
  const { t, tpath } = useLanguage();
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: 2,
          flexWrap: "wrap",
          mt: 10,
        }}
      >
        <Link component={RouterLink} to={tpath("/")}>
          {t("footer.home")}
        </Link>
        <Link component={RouterLink} to={tpath("/pricing")}>
          {t("footer.pricing")}
        </Link>
        <Link component={RouterLink} to={tpath("/blog")}>
          {t("footer.blog")}
        </Link>
        <Link component={RouterLink} to={tpath("/privacy-policy")}>
          {t("footer.privacyPolicy")}
        </Link>
        <Link component={RouterLink} to={tpath("/terms-of-service")}>
          {t("footer.termsOfService")}
        </Link>
      </Box>
      <Copyright />
    </Box>
  );
}
