import { Box, Card, Divider } from "@mui/joy";
import SettingsNameRow from "../components/SettingsNameRow";
import SettingsPasswordRow from "../components/SettingsPasswordRow";
import SettingsEmailRow from "../components/SettingsEmailRow";
import SettingsDeleteAccountRow from "../components/SettingsDeleteAccountRow";
import SettingsSignoutRow from "../components/SettingsSignoutRow";
import SignedInLayout from "../layouts/SignedInLayout";
import SettingsTierRow from "../components/SettingsTierRow";
import useTitle from "../hooks/useTitle";
import SettingsAdminRow from "../components/SettingsAdminRow";
import useProfile from "../hooks/useProfile";
import SettingsLanguageRow from "../components/SettingsLanguageRow";
import useLanguage from "../hooks/useLanguage";

export default function SettingsPage() {
  const { t } = useLanguage();
  useTitle(t("settings.title"));
  const profile = useProfile();
  return (
    <SignedInLayout>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Card>
          <SettingsTierRow />
        </Card>
        <Card>
          <SettingsSignoutRow />
        </Card>
        <Card>
          <SettingsNameRow />
          <Divider />
          <SettingsEmailRow />
          <Divider />
          <SettingsPasswordRow />
        </Card>
        <Card>
          <SettingsLanguageRow />
        </Card>
        {profile?.isAdmin === true && (
          <Card>
            <SettingsAdminRow />
          </Card>
        )}
        <Card>
          <SettingsDeleteAccountRow />
        </Card>
      </Box>
    </SignedInLayout>
  );
}
