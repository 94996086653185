import React from "react";
import { I18nextProvider } from "react-i18next";
import i18next, { i18n } from "i18next";
import locales from "../locales";

function makeLanguage(lng: string) {
  const i18n = i18next.createInstance();
  i18n.init({
    resources: locales,
    lng,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });
  return i18n;
}

const languages = {
  en: makeLanguage("en"),
  es: makeLanguage("es"),
};

interface Language {
  lng: "en" | "es";
  t: i18n["t"];
  tpath: (path: string) => string;
}

export const LanguageContext = React.createContext<Language>({
  lng: "en",
  t: i18next.t,
  tpath: (path) => path,
});

interface Props {
  children: React.ReactNode;
  lng: "en" | "es";
}

export default function LanguageProvider(props: Props) {
  const i18n = languages[props.lng];
  const context = React.useMemo(
    () => ({
      lng: props.lng,
      t: i18n.t,
      tpath: (path: string) => `/${props.lng}${path}`,
    }),
    [props.lng, i18n.t]
  );
  return (
    <I18nextProvider i18n={i18n}>
      <LanguageContext.Provider value={context}>
        {props.children}
      </LanguageContext.Provider>
    </I18nextProvider>
  );
}
