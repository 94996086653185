import { Box, Option, Select, Typography } from "@mui/joy";
import useProfile from "../hooks/useProfile";
import React from "react";
import useLanguage from "../hooks/useLanguage";
import useApi from "../hooks/useApi";
import useSnackbar from "../hooks/useSnackbar";

export default function SettingsLanguageRow() {
  const profile = useProfile();
  const { t } = useLanguage();
  const api = useApi();
  const [loading, setLoading] = React.useState(false);
  const snackbar = useSnackbar();
  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={{ flexGrow: 1, overflow: "hidden" }}>
        <Typography level="title-md">{t("settings.language")}</Typography>
        <Typography level="body-sm" noWrap>
          {t("settings.languageForEmails")}
        </Typography>
      </Box>
      <Box>
        {profile !== null && (
          <Select
            value={profile.locale}
            onChange={(event, value) => {
              setLoading(true);
              api({
                path: "/change-locale",
                method: "POST",
                body: { locale: value },
                onSuccess: () => {
                  snackbar.success(t("localeChange.success"));
                },
                onDone: () => {
                  setLoading(false);
                },
              });
            }}
            disabled={loading}
          >
            <Option value="en">English</Option>
            <Option value="es">Español</Option>
          </Select>
        )}
      </Box>
    </Box>
  );
}
