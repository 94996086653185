import { Box, Typography } from "@mui/joy";
import AnimatedEllipsis from "./AnimatedEllipsis";

interface Props {
  author: "assistant" | "system" | "user";
  content: string | null;
}

export default function ChatMessage(props: Props) {
  return (
    <Box
      sx={(theme) => ({
        borderRadius: 10,
        bgcolor: {
          assistant: theme.palette.warning[600],
          system: theme.palette.danger[700],
          user: theme.palette.neutral[600],
        }[props.author],
        p: 1,
        mr: {
          assistant: 10,
          system: "auto",
          user: undefined,
        }[props.author],
        ml: {
          assistant: undefined,
          system: "auto",
          user: 10,
        }[props.author],
        alignSelf: props.author === "assistant" ? "start" : "end",
        display: "flex",
        flexDirection: "column",
        gap: 1,
      })}
    >
      {props.content === null ? (
        <Typography
          level="body-md"
          fontWeight="bold"
          sx={(theme) => ({
            color: theme.palette.neutral[100],
          })}
        >
          <AnimatedEllipsis />
        </Typography>
      ) : (
        props.content.split("\n").map((line, index) => (
          <Typography
            key={index}
            level="body-md"
            sx={(theme) => ({
              color: theme.palette.neutral[100],
            })}
          >
            {line}
          </Typography>
        ))
      )}
    </Box>
  );
}
